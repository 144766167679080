import { useMutation } from '@apollo/client';
import { Button, Form, Select as AntdSelect, Space, Typography } from 'antd';

import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_SORT_BY_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATUS_TYPES,
  TEMPLATE_OPTION
} from '../../../../../common/constants';
import { SelectVideos } from '../../../../labels/collections/components/FormInputs';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  GET_AUTOGENERATE_BY_AUTHORS,
  GET_AUTOGENERATE_BY_COLLECTIONS,
  GET_AUTOGENERATE_BY_TAGS,
  GET_AUTOGENERATE_BY_TOPIC
} from '../../../graphql/Queries';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    viewAll: true,
    videoTimestamp: true,
    videoDate: true,
    videoAuthor: true,
    videoTopic: true,
    videoTag: true,
    videoOrder: true,
    videoProgressBar: true,
    separator: true,
    searchFilter: true,
    tagsFilter: true,
    videoDescription: true,
    carouselIndicator: true,
    videoCTA: true
  },
  config: {
    autoGenerate: false,
    autoGenerateByType: null,
    autoGenerateById: null,
    autoGenerateSortBy: null,
    videos:
      type === MODULE_TYPES.FEATURED_VIDEO ? { id: '', url: '', title: '' } : []
  }
});

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoDescription',
    label: 'Video Description',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_LIST
    ]
  },
  {
    name: 'searchFilter',
    label: 'Search Filter',
    allowedTypes: [MODULE_TYPES.VIDEO_LIST, MODULE_TYPES.VIDEO_GRID]
  },
  {
    name: 'tagsFilter',
    label: 'Tags Filter',
    allowedTypes: [MODULE_TYPES.VIDEO_LIST, MODULE_TYPES.VIDEO_GRID]
  },
  {
    name: 'videoTimestamp',
    label: 'Timestamp',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoDate',
    label: 'Date',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoAuthor',
    label: 'Speaker',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoTopic',
    label: 'Category',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoTag',
    label: 'Tags',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoOrder',
    label: 'Order in Collection',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoProgressBar',
    label: 'Progress Bar',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'separator',
    label: 'Separator',
    allowedTypes: [MODULE_TYPES.VIDEO_LIST]
  },
  {
    name: 'carouselIndicator',
    label: 'Carousel Indicator',
    allowedTypes: [MODULE_TYPES.FEATURED_VIDEO_SLIDER]
  },
  {
    name: 'videoCTA',
    label: 'Video CTA',
    allowedTypes: [
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  }
];

const AUTO_GENERATED_TYPES = {
  TOPIC: 'TOPIC',
  TAG: 'TAG',
  AUTHOR: 'AUTHOR',
  COLLECTION: 'COLLECTION'
};

const AUTO_GENERATED_OPTIONS = [
  {
    label: 'Topic',
    value: AUTO_GENERATED_TYPES.TOPIC
  },
  {
    label: 'Tag',
    value: AUTO_GENERATED_TYPES.TAG
  },
  {
    label: 'Speaker',
    value: AUTO_GENERATED_TYPES.AUTHOR
  },
  {
    label: 'Collection',
    value: AUTO_GENERATED_TYPES.COLLECTION
  }
];

const AUTO_GENERATED_SORT_BY_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM },
  { label: 'Views', value: AUTO_GENERATED_SORT_BY_TYPES.VIEWS }
];

const getAutoGeneratedByIdProps = {
  [AUTO_GENERATED_TYPES.TOPIC]: {
    query: GET_AUTOGENERATE_BY_TOPIC,
    dataSelector: (data) =>
      data?.topicsAdmin?.topics.map(({ id, name }) => ({
        label: name,
        value: id
      })) || [],
    countSelector: (data) => data?.topicsAdmin?.count ?? 0,
    keys: {
      data: 'topicsAdmin',
      records: 'topics',
      count: 'count'
    }
  },
  [AUTO_GENERATED_TYPES.TAG]: {
    query: GET_AUTOGENERATE_BY_TAGS,
    dataSelector: (data) =>
      data?.tagsAdmin?.tags?.map(({ key, name }) => ({
        label: name,
        value: key
      })) || [],
    countSelector: (data) => data?.tagsAdmin?.count ?? 0,
    keys: {
      data: 'tagsAdmin',
      records: 'tags',
      count: 'count'
    }
  },
  [AUTO_GENERATED_TYPES.AUTHOR]: {
    query: GET_AUTOGENERATE_BY_AUTHORS,
    dataSelector: (data) =>
      data?.authorsAdmin?.authors?.map(({ id, firstName, lastName }) => ({
        label: `${firstName} ${lastName}`,
        value: id
      })) || [],
    countSelector: (data) => data?.authorsAdmin?.count ?? 0,
    keys: {
      data: 'authorsAdmin',
      records: 'authors',
      count: 'count'
    }
  },
  [AUTO_GENERATED_TYPES.COLLECTION]: {
    query: GET_AUTOGENERATE_BY_COLLECTIONS,
    dataSelector: (data) =>
      data?.collectionsAdmin?.collections?.map(({ id, title }) => ({
        label: title,
        value: id
      })) || [],
    countSelector: (data) => data?.collectionsAdmin?.count ?? 0,
    keys: {
      data: 'collectionsAdmin',
      records: 'collections',
      count: 'count'
    }
  }
};

const getAutoGenerateByDataValue = {
  [AUTO_GENERATED_TYPES.TOPIC]: ({ topicId, topicName }) => ({
    label: topicName,
    value: topicId
  }),
  [AUTO_GENERATED_TYPES.TAG]: ({ tagId, tagName }) => ({
    label: tagName,
    value: tagId
  }),
  [AUTO_GENERATED_TYPES.AUTHOR]: ({
    authorId,
    authorFirstName,
    authorLastName
  }) => ({ label: `${authorFirstName} ${authorLastName}`, value: authorId }),
  [AUTO_GENERATED_TYPES.COLLECTION]: ({ collectionId, collectionTitle }) => ({
    label: collectionTitle,
    value: collectionId
  })
};

const MODULE_KEYS = {
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]:
    'videoContinueWatchingCarouselModule',
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: 'videoWatchLaterCarouselModule',
  [MODULE_TYPES.VIDEO_CAROUSEL]: 'videoCarouselModule',
  [MODULE_TYPES.VIDEO_LIST]: 'videoListModule',
  [MODULE_TYPES.VIDEO_GRID]: 'videoGridModule',
  [MODULE_TYPES.FEATURED_VIDEO]: 'featuredVideoModule',
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: 'featuredVideoSliderModule',
  [MODULE_TYPES.VIDEO_PLAYER]: 'videoPlayerModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]:
    'Video Continue Watching Configs',
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: 'Video Watch Later Configs',
  [MODULE_TYPES.VIDEO_CAROUSEL]: 'Video Carousel Configs',
  [MODULE_TYPES.VIDEO_LIST]: 'Video List Configs',
  [MODULE_TYPES.VIDEO_GRID]: 'Video Grid Configs',
  [MODULE_TYPES.FEATURED_VIDEO]: 'Feature Video Configs',
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: 'Feature Video Slider Configs',
  [MODULE_TYPES.VIDEO_PLAYER]: 'Video Player Configs'
};

const { CUSTOM: _CUSTOM, VIDEO, ...restPageTypes } = PAGE_TYPES;
const REST_PAGE_TYPES = Object.values(restPageTypes);

const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.VIDEO_CAROUSEL]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_GRID]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_VIDEO]: [VIDEO, ...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_PLAYER]: [VIDEO]
};

const VideoForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange,
  isDefaultPage,
  pageType
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const isMultiVideo =
    type !== MODULE_TYPES.FEATURED_VIDEO && type !== MODULE_TYPES.VIDEO_PLAYER;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const configProps = Form.useWatch(['config'], form);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const { autoGenerateByType, autoGenerate } =
    configProps ?? initialValues.config;

  const settingsProps = Form.useWatch(['settings'], form);
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        ...(![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && {
          config: {
            ...(type !== MODULE_TYPES.VIDEO_PLAYER && {
              autoGenerate:
                defaultValues?.moduleData?.config?.autoGenerate ?? false,
              autoGenerateByType:
                defaultValues?.moduleData?.config?.autoGenerateByType || null,
              autoGenerateById: idm
                ? TEMPLATE_OPTION
                : getAutoGenerateByDataValue[
                    defaultValues?.moduleData?.config?.autoGenerateByType
                  ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
                  null,
              autoGenerateSortBy:
                defaultValues?.moduleData?.config?.videoAutoGenerateSortBy ||
                null
            }),
            videos: isMultiVideo
              ? defaultValues?.moduleData?.config?.videos?.map(
                  ({ video: { id, title, videoThumbnail } }) => ({
                    id,
                    title,
                    url: videoThumbnail?.url ?? ''
                  })
                ) || []
              : {
                  id: defaultValues?.moduleData?.config?.video?.id ?? '',
                  title: defaultValues?.moduleData?.config?.video?.title ?? '',
                  url:
                    defaultValues?.moduleData?.config?.video?.videoThumbnail
                      ?.url ?? ''
                }
          }
        })
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiVideo
  ]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    // eslint-disable-next-line no-shadow
    isDefaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const isDefault = allowedTemplateConfig && isDefaultModule;

    const {
      videos,
      // eslint-disable-next-line no-shadow
      autoGenerate,
      autoGenerateById,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        ...(![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && {
          config: {
            autoGenerate,
            autoGenerateById: autoGenerateById?.value,
            ...restConfig,
            ...(isDefault
              ? {
                  videoId: '*'
                }
              : {
                  ...(!autoGenerate &&
                    isMultiVideo && {
                      videos:
                        videos?.map(({ id }, i) => ({
                          videoId: id,
                          order: i + 1
                        })) ?? []
                    }),
                  ...(!autoGenerate &&
                    !isMultiVideo && {
                      videoId: videos?.id ?? ''
                    })
                })
          }
        })
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        {![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && (
          <Space className="w-full" direction="vertical">
            {allowedTemplateConfig && pageType === VIDEO && (
              <>
                <Typography.Text>Template Config</Typography.Text>
                <Form.Item
                  className="m-0"
                  name="isDefaultModule"
                  valuePropName="checked"
                >
                  <Switch label="Use Template Data" />
                </Form.Item>
              </>
            )}

            {(!allowedTemplateConfig ||
              (allowedTemplateConfig && !isDefaultModule)) && (
              <>
                <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
                <div>
                  {type !== MODULE_TYPES.VIDEO_PLAYER && (
                    <>
                      <Form.Item
                        name={['config', 'autoGenerate']}
                        valuePropName="checked"
                      >
                        <Switch
                          label={
                            isMultiVideo
                              ? 'Auto Generated Videos'
                              : 'Auto Generated Video'
                          }
                        />
                      </Form.Item>
                      {autoGenerate && (
                        <>
                          <Form.Item
                            label="Auto Generated By Type"
                            name={['config', 'autoGenerateByType']}
                          >
                            <AntdSelect
                              options={AUTO_GENERATED_OPTIONS}
                              placeholder="Select type"
                              onChange={() => {
                                form.setFieldValue(
                                  ['config', 'autoGenerateById'],
                                  null
                                );
                              }}
                            />
                          </Form.Item>
                          {autoGenerateByType && autoGenerateByIdProps && (
                            <Form.Item
                              label="Auto Generated By"
                              name={['config', 'autoGenerateById']}
                            >
                              <Select
                                placeholder="Select"
                                query={autoGenerateByIdProps?.query}
                                variablesSelector={(filter) => ({ filter })}
                                dataSelector={
                                  autoGenerateByIdProps?.dataSelector
                                }
                                keys={autoGenerateByIdProps?.keys}
                                {...(allowedTemplateConfig && {
                                  includeOptions
                                })}
                              />
                            </Form.Item>
                          )}
                          <Form.Item
                            label="Auto Generated Sort By"
                            name={['config', 'autoGenerateSortBy']}
                          >
                            <AntdSelect
                              options={AUTO_GENERATED_SORT_BY_OPTIONS}
                              placeholder="Select sort by"
                            />
                          </Form.Item>
                        </>
                      )}
                    </>
                  )}
                  {!autoGenerate && (
                    <Form.Item
                      label={isMultiVideo ? 'Select Videos' : 'Select Video'}
                      name={['config', 'videos']}
                    >
                      <SelectVideos multiple={isMultiVideo} />
                    </Form.Item>
                  )}
                </div>
              </>
            )}
          </Space>
        )}
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default VideoForm;
