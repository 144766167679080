import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Select as AntdSelect,
  Space,
  Typography
} from 'antd';
import React, { useEffect } from 'react';
import {
  ALIGNMENTS,
  ASSET_CATEGORY,
  MODULE_TYPES,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  STATIC_PAGES,
  STATUS_TYPES,
  TEXT_ALIGNMENTS,
  THEMES
} from '../../../../../common/constants';
import { GET_SLUGS } from '../../../../menus/graphql/Queries';
import { Select, SelectAsset } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  ModuleFields,
  Permissions,
  ShowFields,
  ThemePicker
} from './FormInputs';

export const initialAssetValues = {
  id: '',
  url: ''
};

export const ACTION_TYPES = { INTERNAL: 'INTERNAL', EXTERNAL: 'EXTERNAL' };

export const getActionData = (data, withAsset = true) => ({
  ...data,
  internalPageType: data?.internalPageType ?? null,
  ...(data?.type === ACTION_TYPES.INTERNAL && {
    ...(data?.internalPageType === PAGE_TYPES.STATIC
      ? { slug: data?.url }
      : {
          slug: data?.url
            ? {
                label: `${data?.url}`,
                value: data?.url
              }
            : null
        })
  }),
  ...(withAsset && {
    asset: data?.asset ?? {
      ...initialAssetValues
    }
  })
});

export const initialActionValues = {
  title: '',
  type: ACTION_TYPES.INTERNAL,
  internalPageType: null,
  slug: null,
  asset: {
    ...initialAssetValues
  },
  url: ''
};

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    title: true,
    image: true,
    description: true,
    primaryAction: true,
    secondaryAction: true,
    eventDetails: true,
    textFields: true
  },
  config: {
    assetType: ASSET_CATEGORY.IMAGE,
    asset: {
      ...initialAssetValues
    },
    eventTime: '',
    eventLocation: '',
    theme: THEMES.BLUE,
    primaryAction: {
      ...initialActionValues
    },
    secondaryAction: {
      ...initialActionValues
    },
    alignment: TEXT_ALIGNMENTS.LEFT
  }
};

const ACTION_TYPE_OPTIONS = [
  { label: 'Internal', value: ACTION_TYPES.INTERNAL },
  { label: 'External', value: ACTION_TYPES.EXTERNAL }
];

const ASSET_TYPES_OPTIONS = [
  { label: 'Image', value: ASSET_CATEGORY.IMAGE },
  { label: 'Video', value: ASSET_CATEGORY.VIDEO }
];

const ALIGNMENT_OPTIONS = [
  { label: 'Left', value: TEXT_ALIGNMENTS.LEFT },
  { label: 'Center', value: TEXT_ALIGNMENTS.CENTER },
  { label: 'Right', value: TEXT_ALIGNMENTS.RIGHT },
  { label: 'Justify', value: TEXT_ALIGNMENTS.JUSTIFY }
];

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.CTA_BANNER_WITH_TEXT]
  },
  {
    name: 'image',
    label: 'Image',
    allowedTypes: [
      MODULE_TYPES.CTA_BLOCK,
      MODULE_TYPES.CTA_EVENT_BLOCK,
      MODULE_TYPES.CTA_BANNER_WITH_TEXT
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.CTA_BLOCK,
      MODULE_TYPES.CTA_EVENT_BLOCK,
      MODULE_TYPES.CTA_BANNER_WITH_TEXT,
      MODULE_TYPES.HERO
    ]
  },
  {
    name: 'primaryAction',
    label: 'primary Action',
    allowedTypes: [MODULE_TYPES.HERO]
  },
  {
    name: 'secondaryAction',
    label: 'Secondary Action',
    allowedTypes: [
      MODULE_TYPES.CTA_BLOCK,
      MODULE_TYPES.CTA_EVENT_BLOCK,
      MODULE_TYPES.HERO
    ]
  },
  {
    name: 'eventDetails',
    label: 'Event Details',
    allowedTypes: [MODULE_TYPES.CTA_EVENT_BLOCK]
  },
  {
    name: 'textFields',
    label: 'Text Fields',
    allowedTypes: [MODULE_TYPES.HERO]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.CTA_BLOCK]: 'ctaBlockModule',
  [MODULE_TYPES.CTA_BANNER]: 'ctaBannerModule',
  [MODULE_TYPES.CTA_EVENT_BLOCK]: 'ctaEventBlockModule',
  [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: 'ctaBannerWithTextModule',
  [MODULE_TYPES.HERO]: 'heroModule'
};

const CTA_THEMES = [
  THEMES.BLUE,
  THEMES.YELLOW,
  THEMES.BLUE_TINT,
  THEMES.MOSS,
  THEMES.MOSS_TINT,
  THEMES.BLUE_SHADE,
  THEMES.NEUTRAL_900,
  THEMES.NEUTRAL_400
];

const CONFIG_TITLE = {
  [MODULE_TYPES.CTA_BLOCK]: 'CTA Block Configs',
  [MODULE_TYPES.CTA_EVENT_BLOCK]: 'CTA Event Configs',
  [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: 'CTA Banner with Text Configs',
  [MODULE_TYPES.CTA_BANNER]: 'CTA Banner Configs',
  [MODULE_TYPES.HERO]: 'Hero Configs'
};

export const ActionButton = ({ name, label, showAsset, form }) => {
  const namePath = ['config', name];
  const { internalPageType, type } =
    Form.useWatch(['config'], form)?.[name] ?? {};

  return (
    <Space className="w-full" direction="vertical">
      <Typography.Text>{label}</Typography.Text>
      <div>
        <Form.Item label="Title" name={[...namePath, 'title']}>
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item label="Type" name={[...namePath, 'type']}>
          <AntdSelect
            options={ACTION_TYPE_OPTIONS}
            onChange={() => {
              form.resetFields([
                [...namePath, 'internalPageType'],
                [...namePath, 'slug'],
                [...namePath, 'url']
              ]);
            }}
          />
        </Form.Item>

        {type === ACTION_TYPES.INTERNAL && (
          <>
            <Form.Item
              label="Page Type"
              name={[...namePath, 'internalPageType']}
            >
              <AntdSelect
                options={PAGE_TYPES_OPTIONS}
                placeholder="Select page type"
                onChange={() => {
                  form.resetFields([
                    [...namePath, 'slug'],
                    [...namePath, 'url']
                  ]);
                }}
              />
            </Form.Item>
            {internalPageType && (
              <>
                {internalPageType === PAGE_TYPES.STATIC ? (
                  <Form.Item label="Page" name={[...namePath, 'slug']}>
                    <AntdSelect
                      options={STATIC_PAGES}
                      onChange={(value) => {
                        form.setFieldValue([...namePath, 'url'], `${value}`);
                      }}
                      placeholder="Select page"
                    />
                  </Form.Item>
                ) : (
                  <Form.Item label="Page" name={[...namePath, 'slug']}>
                    <Select
                      placeholder="Select"
                      query={GET_SLUGS}
                      variablesSelector={(filter) => ({
                        filter: {
                          ...filter,
                          pageType: internalPageType
                        }
                      })}
                      dataSelector={(data) => {
                        return (
                          data?.slugs?.slugs?.map(({ slug }) => ({
                            label: `/${slug}`,
                            value: slug
                          })) ?? []
                        );
                      }}
                      keys={{
                        data: 'slugs',
                        records: 'slugs',
                        count: 'count'
                      }}
                      onChange={({ value }) => {
                        form.setFieldValue([...namePath, 'url'], `${value}`);
                      }}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </>
        )}

        <Form.Item label="URL" name={[...namePath, 'url']}>
          <Input
            readOnly={type === ACTION_TYPES.INTERNAL}
            disabled={type === ACTION_TYPES.INTERNAL}
            placeholder="Enter url"
          />
        </Form.Item>

        {showAsset && (
          <Form.Item label="Icon" name={[...namePath, 'asset']}>
            <SelectAsset
              modalTitle="Select Icon"
              categoryKey={ASSET_CATEGORY.ICON}
              btnText="Icon"
              dataSelector={({ id, url }) => ({
                id,
                url
              })}
            />
          </Form.Item>
        )}
      </div>
    </Space>
  );
};

const AssetInput = ({ type = ASSET_CATEGORY.IMAGE }) => {
  const label = {
    [ASSET_CATEGORY.IMAGE]: 'Image',
    [ASSET_CATEGORY.VIDEO]: 'Video'
  }[type];

  const dataSelector = {
    [ASSET_CATEGORY.IMAGE]: ({ id, url }) => ({
      id,
      url
    }),
    [ASSET_CATEGORY.VIDEO]: ({
      id,
      serviceImageThumbnail,
      serviceVideoThumbnail
    }) => ({
      id,
      url: serviceVideoThumbnail || serviceImageThumbnail
    })
  }[type];
  return (
    <Form.Item label={label} name={['config', 'asset']}>
      <SelectAsset
        modalTitle="Select Image"
        categoryKey={type}
        btnText={label}
        dataSelector={dataSelector}
      />
    </Form.Item>
  );
};

const CTAForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const settingsProps = Form.useWatch(['settings'], form);
  const { assetType = ASSET_CATEGORY.IMAGE } =
    Form.useWatch(['config'], form) ?? {};
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const hasButtonIcon = [MODULE_TYPES.CTA_BLOCK, MODULE_TYPES.HERO].includes(
    type
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const primaryAction = defaultValues?.moduleData?.config?.primaryAction;
      const secondaryAction =
        defaultValues?.moduleData?.config?.secondaryAction;

      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          alignment:
            defaultValues?.moduleData?.config?.alignment ?? ALIGNMENTS.LEFT,
          asset: {
            id: defaultValues?.moduleData?.config?.asset?.id ?? '',
            url: defaultValues?.moduleData?.config?.asset?.url ?? '',
            ...(MODULE_TYPES.HERO === type &&
              defaultValues?.moduleData?.config?.asset?.categoryKey ===
                ASSET_CATEGORY.VIDEO && {
                url:
                  defaultValues?.moduleData?.config?.asset
                    ?.serviceVideoThumbnail ||
                  defaultValues?.moduleData?.config?.asset
                    ?.serviceImageThumbnail
              })
          },
          assetType:
            defaultValues?.moduleData?.config?.asset?.categoryKey ??
            ASSET_CATEGORY.IMAGE,
          ...([MODULE_TYPES.CTA_EVENT_BLOCK, MODULE_TYPES.HERO].includes(
            type
          ) && {
            eventTime: defaultValues?.moduleData?.config?.eventTime,
            eventLocation: defaultValues?.moduleData?.config?.eventLocation
          }),
          ...(type !== MODULE_TYPES.CTA_BANNER && {
            theme: defaultValues?.moduleData?.config?.theme ?? THEMES.BLUE,
            primaryAction: getActionData(primaryAction, hasButtonIcon),
            secondaryAction: getActionData(secondaryAction, hasButtonIcon)
          })
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    hasButtonIcon
  ]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    // eslint-disable-next-line no-shadow
    const {
      assetType: _assetType,
      asset,
      primaryAction,
      secondaryAction,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfig,
          assetId: asset?.id ?? null,
          ...(type !== MODULE_TYPES.CTA_BANNER && {
            primaryAction: {
              title: primaryAction?.title ?? '',
              type: primaryAction?.type ?? ACTION_TYPES.EXTERNAL,
              internalPageType: primaryAction?.internalPageType,
              url: primaryAction?.url ?? '',
              ...(hasButtonIcon && {
                assetId: primaryAction?.asset?.id ?? null
              })
            },
            ...(type !== MODULE_TYPES.CTA_BANNER_WITH_TEXT && {
              secondaryAction: {
                title: secondaryAction?.title ?? '',
                type: secondaryAction?.type ?? ACTION_TYPES.EXTERNAL,
                internalPageType: secondaryAction?.internalPageType,
                url: secondaryAction?.url ?? '',
                ...(hasButtonIcon && {
                  assetId: secondaryAction?.asset?.id ?? null
                })
              }
            })
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields
        {...(type === MODULE_TYPES.HERO && {
          title: {
            InputComponent: Input.TextArea,
            rows: 3
          }
        })}
      />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            {type === MODULE_TYPES.HERO && (
              <Form.Item
                label="Title & Description Alignment"
                name={['config', 'alignment']}
              >
                <AntdSelect
                  placeholder="Select alignment"
                  options={ALIGNMENT_OPTIONS}
                />
              </Form.Item>
            )}
            {[MODULE_TYPES.CTA_EVENT_BLOCK, MODULE_TYPES.HERO].includes(
              type
            ) && (
              <>
                <Form.Item label="Event Time" name={['config', 'eventTime']}>
                  <Input placeholder="Enter event time" />
                </Form.Item>
                <Form.Item
                  label="Event Location"
                  name={['config', 'eventLocation']}
                >
                  <Input placeholder="Enter event location" />
                </Form.Item>
              </>
            )}

            {![MODULE_TYPES.CTA_BANNER, MODULE_TYPES.HERO].includes(type) && (
              <Form.Item label="Theme" name={['config', 'theme']}>
                <ThemePicker themes={CTA_THEMES} />
              </Form.Item>
            )}
            {MODULE_TYPES.HERO === type && (
              <>
                <Form.Item
                  label="Background Type"
                  name={['config', 'assetType']}
                >
                  <AntdSelect
                    options={ASSET_TYPES_OPTIONS}
                    onChange={() => {
                      form.setFieldValue(
                        ['config', 'asset'],
                        initialAssetValues
                      );
                    }}
                  />
                </Form.Item>
              </>
            )}
            <AssetInput type={assetType} />
            {type !== MODULE_TYPES.CTA_BANNER && (
              <>
                <ActionButton
                  name="primaryAction"
                  label="Primary Action"
                  showAsset={hasButtonIcon}
                  form={form}
                />
                {type !== MODULE_TYPES.CTA_BANNER_WITH_TEXT && (
                  <ActionButton
                    name="secondaryAction"
                    label="Secondary Action"
                    showAsset={hasButtonIcon}
                    form={form}
                  />
                )}
              </>
            )}
          </div>
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default CTAForm;
