import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useState } from 'react';
import * as urlSlug from 'url-slug';
import { MODULES, ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import history from '../../../historyData';
import { CREATE_PAGE } from '../graphql/Mutations';
import { Permissions } from './pageModules/moduleForms/FormInputs';

const { required } = formValidatorRules;

const AddPage = () => {
  const [form] = Form.useForm();

  const [buttonLoading, setButtonLoading] = useState(false);

  const [createPageMutate] = useMutation(CREATE_PAGE, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.PAGES_MODULE);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const formData = {
        ...values,
        permissions: values?.permissions?.map(({ value }) => value),
        slug: values.slug.startsWith('/')
          ? values.slug.substring(1)
          : values.slug,
        type: 'CUSTOM'
      };
      const response = await createPageMutate({
        variables: {
          data: {
            ...formData
          }
        }
      });
      if (response?.data?.createPage) {
        setButtonLoading(false);

        history?.replace(
          `${ROUTES?.PAGES_MODULE}/${response?.data?.createPage?.page?.id}/edit`
        );
      }
    } catch (error) {
      setButtonLoading(false);
      return error;
    }
  };

  const handleNameChange = (e) => {
    form?.setFieldValue('slug', `/${urlSlug?.convert(e?.target?.value)}`);
  };

  return (
    <>
      <PageHeader menu={MODULES?.PAGES} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            name="create-page"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
              ]}
            >
              <Input
                placeholder="Enter title"
                disabled={buttonLoading}
                onChange={handleNameChange}
              />
            </Form.Item>

            <Form.Item
              name="slug"
              label="Page Slug"
              rules={[{ required, message: 'Please enter slug!' }]}
            >
              <Input placeholder="Enter slug" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item name="description" label="Page Description">
              <Input placeholder="Enter description" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item name="metaHeader" label="Meta Header">
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item name="metaFooter" label="Meta Footer">
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <Permissions />

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPage;
