import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Input, Modal, Tooltip } from 'antd';
import moment from 'moment';
import { DownloadSimple, Eye } from 'phosphor-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import videoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  IMPORT_VIDEO_DATE_FORMAT,
  MODULES,
  ROUTES,
  SORT_FIELD
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import history from '../../../historyData';
import CustomCard from '../../component/card/Cscard';
import { IMPORT_PLATFORM_VIDEO_TO_ASSET } from '../../video-platforms/graphql/Mutations';
import {
  GET_VIDEO_BY_PLATFORM,
  GET_VIDEO_URL_BY_PLATFORM_AND_VIDEOID
} from '../../video-platforms/graphql/Queries';
import { SelectPlatForm } from '../../videos/components/FormInputs';
import { SelectLanguage } from './AddEditVideo';

const { TextArea } = Input;

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  where
}) => ({
  filter: {
    limit,
    skip: offset,
    search
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  },
  where
});

const dataSelector = (data) => ({
  data: data?.getVideosByPlatform?.platformVideos || [],
  count: data?.getVideosByPlatform?.count || 0
});

const initialValues = {
  title: '',
  description: '',
  languageCode: {
    label: 'English',
    value: 'EN'
  }
};

const ImportPlatformVideoModal = ({
  open,
  platform,
  videoPlatformData,
  onClose
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open && videoPlatformData) {
      form.setFieldValue('title', videoPlatformData?.title);
      form.setFieldValue('description', videoPlatformData?.description);
    }
  }, [open, videoPlatformData]);

  const [createPlatformVideoAssetMutate, { loading }] = useMutation(
    IMPORT_PLATFORM_VIDEO_TO_ASSET
  );
  const handleSubmit = async (values) => {
    const textData = {
      title: values?.title,
      description: values?.description,
      languageCode: values?.languageCode?.value
    };
    const response = await createPlatformVideoAssetMutate({
      variables: {
        where: {
          platformId: platform?.value,
          videoId: videoPlatformData?.id
        },
        data: {
          ...textData
        }
      }
    });
    if (response?.data?.importPlatformVideoToAsset) {
      history?.replace(ROUTES?.VIDEOS);
    }
  };
  return (
    <Modal
      destroyOnClose
      okButtonProps={{
        className: 'text-btn',
        form: 'import-video-form',
        htmlType: 'submit',
        loading,
        type: 'text'
      }}
      cancelButtonProps={{
        className: 'text-btn2',
        type: 'text'
      }}
      title="Import Video"
      open={open}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues || { isActive: true }}
        name="create-asset"
        layout="vertical"
        id="import-video-form"
        className="add-edit-form"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="title"
          label="Title"
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
          ]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <TextArea rows={2} placeholder="Enter description" />
        </Form.Item>
        <Form.Item name="languageCode" label="Language Code">
          <SelectLanguage />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ImportVideo = () => {
  const ref = useRef();
  const [platform, setPlatform] = useState(null);
  const [platformData, setPlatformData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: SORT_FIELD.slice(0, 1)
    }
  });
  const [getVideoUrl] = useLazyQuery(GET_VIDEO_URL_BY_PLATFORM_AND_VIDEOID, {
    fetchPolicy: 'network-only'
  });
  const handlePlatformChange = (_, value) => {
    setPlatform(value);
  };

  const handleImport = (data) => {
    setIsOpen(true);
    setPlatformData(data);
  };

  const handleView = async (videoId) => {
    const { data } = await getVideoUrl({
      variables: {
        where: {
          videoId,
          platformId: platform?.value
        }
      }
    });
    if (data?.getVideoUrlByPlatformAndVideoId && ref.current.showPreview) {
      ref.current.showPreview({
        title: data?.getVideoUrlByPlatformAndVideoId.title,
        url: data?.getVideoUrlByPlatformAndVideoId.url,
        type: ASSET_CATEGORY.VIDEO
      });
    }
  };

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      where: {
        id: platform ? platform?.value : ''
      }
    }),
    [filterProps.filters, platform]
  );

  const columns = [
    {
      title: 'Video',
      dataIndex: 'imageThumbnailUrl',
      key: 'imageThumbnailUrl',
      editable: true,
      width: '12%',
      render: (text, record) => (
        <img
          className="group-img-table pointer"
          src={
            record?.videoThumbnailUrl ??
            record?.imageThumbnailUrl ??
            videoPlaceholder
          }
          alt={`${record?.title}`}
        />
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '22%'
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '12%',
      render: (text, record) => (
        <div>
          <Tooltip title="View Video">
            <EyeOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleView(record?.id);
              }}
            />
          </Tooltip>
          <span onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Import Video">
              <DownloadOutlined onClick={() => handleImport(record)} />
            </Tooltip>
          </span>
        </div>
      )
    }
  ];

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSuccess = () => {
    closeModal();
  };

  return (
    <>
      <ImportPlatformVideoModal
        open={isOpen}
        videoPlatformData={platformData}
        platform={platform}
        onClose={closeModal}
        onSuccess={handleSuccess}
      />
      <PageHeader menu={MODULES?.ASSETS} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Videos' }}
        extraFilters={
          <SelectPlatForm
            onChange={handlePlatformChange}
            value={platform}
            setPlatform={setPlatform}
          />
        }
      />
      {platform && (
        <PageList
          ref={ref}
          filters={filters}
          listMode={filterProps.listMode}
          query={GET_VIDEO_BY_PLATFORM}
          variablesSelector={variablesSelector}
          dataSelector={dataSelector}
          GridProps={{
            renderItem: (item) => (
              <CustomCard
                alt={item?.title}
                src={
                  item?.videoThumbnailUrl ??
                  item?.imageThumbnailUrl ??
                  videoPlaceholder
                }
                title={moment(item?.createdAt).format(IMPORT_VIDEO_DATE_FORMAT)}
                heading={item?.title}
                description={item?.description}
                primaryOnClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  handleView(item?.id);
                }}
                secondaryOnClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  handleImport(item);
                }}
                primary={
                  <>
                    <Eye size={16} />
                    View
                  </>
                }
                secondary={
                  <>
                    <DownloadSimple size={16} />
                    Import
                  </>
                }
              />
            )
          }}
          TableProps={{ columns }}
        />
      )}
    </>
  );
};

export default ImportVideo;
