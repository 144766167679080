import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Select as AntdSelect, Space } from 'antd';
import React, { useEffect } from 'react';
import {
  ASSET_CATEGORY,
  MODULES,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  ROUTES,
  STATIC_PAGES
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import {
  initialAsset,
  Select,
  SelectAsset
} from '../videos/components/FormInputs';
import { CREATE_MENU, UPDATE_MENU } from './graphql/Mutations';
import { GET_MENU, GET_SLUGS } from './graphql/Queries';

export const MENU_POSITIONS = { HEADER: 'HEADER', FOOTER: 'FOOTER' };
const MENU_TYPES = { INTERNAL: 'INTERNAL', EXTERNAL: 'EXTERNAL' };

const initialValues = {
  name: '',
  icon: {
    ...initialAsset
  },
  position: MENU_POSITIONS.HEADER,
  showInSideMenu: true,
  type: MENU_TYPES.INTERNAL,
  internalPageType: null,
  slug: null,
  url: '',
  altTag: '',
  openInSameTab: true
};

export const MENU_POSITIONS_OPTIONS = [
  { label: 'Header', value: MENU_POSITIONS.HEADER },
  { label: 'Footer', value: MENU_POSITIONS.FOOTER }
];
const MENU_TYPES_OPTIONS = [
  { label: 'Internal', value: MENU_TYPES.INTERNAL },
  { label: 'External', value: MENU_TYPES.EXTERNAL }
];

const AddEditMenu = ({ history, match: { params }, location: { search } }) => {
  const [form] = Form.useForm();
  const { menuId } = params;
  const isEdit = !!menuId;

  const type = Form.useWatch(['type'], form);

  const internalPageType = Form.useWatch(['internalPageType'], form);

  const [fetchMenuDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_MENU,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateMenu, { loading }] = useMutation(
    isEdit ? UPDATE_MENU : CREATE_MENU
  );

  useEffect(() => {
    if (isEdit && !!menuId) {
      fetchMenuDetails({
        variables: {
          id: menuId
        }
      })
        .then(({ data }) => {
          if (data?.menuAdmin) {
            const menu = data.menuAdmin;
            form.setFieldsValue({
              name: menu?.name ?? '',
              icon: menu?.icon
                ? {
                    id: menu?.icon?.id,
                    url: menu?.icon?.url
                  }
                : {
                    ...initialAsset
                  },
              showInSideMenu: menu?.showInSideMenu ?? true,
              openInSameTab: menu?.openInSameTab ?? true,
              altTag: menu?.altTag ?? '',
              type: menu?.type ?? MENU_TYPES.INTERNAL,
              internalPageType: menu?.internalPageType ?? null,
              ...(menu?.type === MENU_TYPES.INTERNAL && {
                ...(menu?.internalPageType === PAGE_TYPES.STATIC
                  ? { slug: menu?.url }
                  : {
                      slug: menu?.url
                        ? { label: `/${menu?.url}`, value: menu?.url }
                        : null
                    })
              }),
              url: `/${menu?.url}` ?? '',
              position: MENU_POSITIONS.HEADER
            });
          }
        })
        .catch();
    } else {
      const query = new URLSearchParams(search);
      const position = query?.get('position') ?? MENU_POSITIONS.HEADER;
      form.setFieldValue('position', position);
    }
  }, [isEdit, menuId, form, fetchMenuDetails, search]);

  const handleSubmit = ({ icon, slug: _slug, ...rest }) => {
    const payload = {
      ...rest,
      iconId: icon?.id || null,
      ...(rest.type === MENU_TYPES.INTERNAL && {
        url: rest.url?.startsWith('/') ? rest.url?.substring(1) : rest.url
      }),
      ...(!isEdit && {
        // FIXME: Order:- remove field from back-end
        order: 1
      })
    };
    addUpdateMenu({
      variables: {
        data: payload,
        ...(isEdit && { id: menuId })
      }
    }).then(() => {
      history.push(ROUTES?.MENUS);
    });
  };

  const handleCancel = () => {
    history.push(ROUTES?.MENUS);
  };

  return (
    <>
      <PageHeader menu={MODULES?.MENUS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={fetchingDetails}
          >
            <Form.Item
              label="Name"
              name="name"
              required
              rules={[
                formValidatorRules?.required('Please enter name!'),
                formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
              ]}
            >
              <Input placeholder="Enter name" />
            </Form.Item>
            {!isEdit && (
              <>
                <Form.Item
                  label="Position"
                  name="position"
                  rules={[
                    {
                      required: true,
                      message: 'Please select position!'
                    }
                  ]}
                >
                  <AntdSelect
                    options={MENU_POSITIONS_OPTIONS}
                    placeholder="Select position"
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select type!'
                }
              ]}
            >
              <AntdSelect
                options={MENU_TYPES_OPTIONS}
                placeholder="Select type"
                onChange={(value) => {
                  form.setFieldValue(
                    'openInSameTab',
                    value === MENU_TYPES.INTERNAL
                  );
                  form.resetFields(['internalPageType', 'slug', 'url']);
                }}
              />
            </Form.Item>
            {type === MENU_TYPES.INTERNAL && (
              <>
                <Form.Item
                  label="Page Type"
                  name="internalPageType"
                  rules={[
                    {
                      required: type === MENU_TYPES.INTERNAL,
                      message: 'Please select page type!'
                    }
                  ]}
                >
                  <AntdSelect
                    options={PAGE_TYPES_OPTIONS}
                    placeholder="Select page type"
                    onChange={() => {
                      form.resetFields(['slug', 'url']);
                    }}
                  />
                </Form.Item>
                {internalPageType && (
                  <>
                    {internalPageType === PAGE_TYPES.STATIC ? (
                      <Form.Item
                        label="Page"
                        name="slug"
                        rules={[
                          {
                            required: type === MENU_TYPES.INTERNAL,
                            message: 'Please select page!'
                          }
                        ]}
                      >
                        <AntdSelect
                          options={STATIC_PAGES}
                          onChange={(value) => {
                            form.setFieldValue('url', `/${value}`);
                            form.validateFields(['url']);
                          }}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Page"
                        name="slug"
                        rules={[
                          {
                            required: type === MENU_TYPES.INTERNAL,
                            message: 'Please select page!'
                          }
                        ]}
                      >
                        <Select
                          placeholder="Select"
                          query={GET_SLUGS}
                          variablesSelector={(filter) => ({
                            filter: {
                              ...filter,
                              pageType: internalPageType
                            }
                          })}
                          dataSelector={(data) => {
                            return (
                              data?.slugs?.slugs?.map(({ slug }) => ({
                                label: `/${slug}`,
                                value: slug
                              })) ?? []
                            );
                          }}
                          keys={{
                            data: 'slugs',
                            records: 'slugs',
                            count: 'count'
                          }}
                          onChange={({ value }) => {
                            form.setFieldValue('url', `/${value}`);
                            form.validateFields(['url']);
                          }}
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </>
            )}
            <Form.Item
              label="URL"
              name="url"
              rules={[
                {
                  required: true,
                  message: 'Please enter URL!'
                }
              ]}
            >
              <Input
                placeholder="Enter url"
                readOnly={type === MENU_TYPES.INTERNAL}
                disabled={type === MENU_TYPES.INTERNAL}
              />
            </Form.Item>
            <Form.Item label="Alt Tag" name="altTag">
              <Input placeholder="Enter alt tag" />
            </Form.Item>
            <Form.Item label="Icon" name="icon">
              <SelectAsset
                modalTitle="Select Icon"
                categoryKey={ASSET_CATEGORY.ICON}
                btnText="Icon"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <Form.Item
              className="m-0"
              name="showInSideMenu"
              valuePropName="checked"
            >
              <Switch label="Show In Side Menu" />
            </Form.Item>
            <Form.Item
              className="m-0"
              name="openInSameTab"
              valuePropName="checked"
            >
              <Switch label="Open In Same Tab" />
            </Form.Item>
            <div className="d-flex button-section mb-8 mt-8">
              <Space>
                <Button
                  disabled={loading || fetchingDetails}
                  loading={loading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditMenu;
