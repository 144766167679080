import { CheckOutlined } from '@ant-design/icons';
import { NetworkStatus, useQuery } from '@apollo/client';
import {
  DatePicker as AntdDatePicker,
  Select as AntdSelect,
  Button,
  Card,
  Empty,
  Space,
  Spin,
  Typography,
  theme
} from 'antd';
import dayjs from 'dayjs';
import { debounce, get, isFunction, isObject } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AudioPlaceholder from '../../../assets/images/audioPlaceholder.jpg';
import DocumentPlaceholder from '../../../assets/images/docPlaceholder.png';
import videoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import { ASSET_CATEGORY, EDITOR_MODULES } from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import Image from '../../../components/Image';
import SelectableModal from '../../../components/SelectableModal';
import { GET_ASSETS } from '../../assets/graphql/Queries';
import {
  GET_VIDEO_BY_PLATFORM,
  GET_VIDEO_PLATFORMS
} from '../../video-platforms/graphql/Queries';
import { GET_AUTHORS } from '../graphql/Queries';
import Preview from './Preview';

// Constants
export const initialAuthor = {
  id: '',
  firstName: '',
  lastName: '',
  imageURL: '',
  color: ''
};

export const initialAsset = {
  id: '',
  url: ''
};

const DEFAULT_PLACEHOLDER_IMAGE = {
  [ASSET_CATEGORY.VIDEO]: videoPlaceholder,
  [ASSET_CATEGORY.IMAGE]: videoPlaceholder,
  [ASSET_CATEGORY.DOCUMENT]: DocumentPlaceholder,
  [ASSET_CATEGORY.TEXT]: DocumentPlaceholder,
  [ASSET_CATEGORY.AUDIO]: AudioPlaceholder
};

const getAssetImage = (item, key) =>
  ({
    [ASSET_CATEGORY.VIDEO]:
      item.serviceVideoThumbnail ?? item.serviceImageThumbnail,
    [ASSET_CATEGORY.IMAGE]:
      getImageUrl(item.url, {
        height: 200,
        width: 200
      }) || videoPlaceholder,
    [ASSET_CATEGORY.ICON]:
      getImageUrl(item.url, {
        height: 200,
        width: 200
      }) || videoPlaceholder
  }[key] ?? DEFAULT_PLACEHOLDER_IMAGE[key]);

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return { open: isOpen, openModal, closeModal };
};

const LIMIT = 30;
// Components
export const Select = ({
  query,
  dataSelector,
  variablesSelector,
  multiple = false,
  searchable = true,
  limit = LIMIT,
  keys,
  excludeOptions = [],
  includeOptions = [],
  queryOptions = {},
  notFoundContent = 'No data!',
  ...rest
}) => {
  const [search, setSearch] = useState('');

  const { networkStatus, data, fetchMore } = useQuery(query, {
    ...(variablesSelector &&
      isFunction(variablesSelector) && {
        variables: variablesSelector({ skip: 0, limit, search })
      }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    ...queryOptions
  });

  const options = useMemo(
    () =>
      data
        ? [
            ...includeOptions,
            ...(dataSelector(data)?.filter(
              ({ value }) => !excludeOptions?.includes(value)
            ) ?? [])
          ]
        : [],
    [data, includeOptions, excludeOptions]
  );
  const count = useMemo(
    () =>
      data && isObject(keys)
        ? get(data, [keys?.data, keys?.count], 0) +
          includeOptions?.length -
          excludeOptions?.length
        : 0,
    [data, includeOptions, excludeOptions]
  );

  const hasMoreData = count > options.length;
  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  const handleClose = (isOpen) => {
    if (!isOpen) setSearch('');
  };

  const fetchMoreRecords = useCallback(
    ({ skip, search: searchValue } = {}) => {
      fetchMore({
        variables: variablesSelector({ skip, limit, search: searchValue }),
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            fetchMoreResult &&
            keys &&
            isObject(keys) &&
            'data' in keys &&
            'records' in keys &&
            'count' in keys
          ) {
            return {
              ...prev,
              [keys.data]: {
                ...get(prev, keys.data),
                [keys.records]: [
                  ...get(prev, [keys.data, keys.records]),
                  ...get(fetchMoreResult, [keys.data, keys.records])
                ],
                [keys.count]: get(fetchMoreResult, [keys.data, keys.count])
              }
            };
          }
          return { ...prev };
        }
      });
    },
    [fetchMore]
  );

  const handleScroll = (e) => {
    e.stopPropagation();
    const { target } = e;
    if (!isFetchingMore && hasMoreData && !loading) {
      if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
        fetchMoreRecords({ skip: options.length, search });
      }
    }
  };

  return (
    <>
      <AntdSelect
        labelInValue
        options={options}
        filterOption={false}
        loading={loading || isFetchingMore}
        notFoundContent={
          loading ? (
            <Spin size="small" />
          ) : (
            <Empty className="text-white-70" description={notFoundContent} />
          )
        }
        onDropdownVisibleChange={handleClose}
        {...(multiple && {
          mode: 'multiple'
        })}
        {...(searchable && {
          showSearch: true,
          onSearch: debounce(setSearch, 500)
        })}
        {...(isFunction(variablesSelector) &&
          isObject(keys) && {
            onPopupScroll: handleScroll
          })}
        {...rest}
      />
    </>
  );
};

export const DatePicker = ({ value, onChange, ...rest }) => {
  return (
    <AntdDatePicker
      style={{
        width: '100%'
      }}
      disabledDate={(day) => dayjs().subtract(1, 'day').isAfter(day)}
      value={value ? dayjs(value) : null}
      onChange={(_, date) =>
        onChange(date ? dayjs(date).format('YYYY-MM-DD') : '')
      }
      {...rest}
    />
  );
};

export const SelectAuthor = ({
  value,
  onChange,
  multiple = false,
  id: fieldId
}) => {
  const { open, openModal, closeModal } = useModal();

  const handleCardSelect = ({
    id,
    firstName,
    lastName,
    image,
    primaryColor
  }) => {
    if (multiple) {
      const isPresent = value.find((items) => items.id === id);
      if (isPresent) {
        onChange(value.filter((items) => items.id !== id));
      } else {
        onChange([
          ...value,
          {
            id,
            firstName,
            lastName,
            color: primaryColor,
            imageURL: image?.url ?? ''
          }
        ]);
      }
      return;
    }
    onChange({
      id,
      firstName,
      lastName,
      color: primaryColor,
      imageURL: image?.url ?? ''
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value.filter((items) => items.id !== item.id));
    } else {
      onChange({
        ...initialAuthor
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Speakers' : 'Select Speaker'}
        open={open}
        onClose={closeModal}
        query={GET_AUTHORS}
        variablesSelector={(offset, limit, search) => ({
          offset,
          limit,
          search
        })}
        dataSelector={(data) => data.authorsAdmin.authors}
        keys={{
          data: 'authorsAdmin',
          records: 'authors',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value.map((val) => val.id).includes(item.id)
            : value?.id === item?.id;
          return (
            <Card
              className="selectable-modal-card cs-card"
              cover={
                <>
                  {isSelected && (
                    <span className="checked-icon">
                      <CheckOutlined />
                    </span>
                  )}
                  <Image
                    className="label-poster"
                    blurHash={item?.image?.blurhash}
                    src={
                      getImageUrl(item.image?.url, {
                        height: 200,
                        width: 200
                      }) || videoPlaceholder
                    }
                    alt=""
                  />
                </>
              }
              onClick={() => handleCardSelect(item)}
            >
              <Card.Meta
                title={
                  <span className="label-title">
                    {`${item.firstName} ${item.lastName}`}
                  </span>
                }
              />
            </Card>
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value.length > 0 &&
              value.map((item) => (
                <Preview
                  key={item.id}
                  bgImg={
                    getImageUrl(item.imageURL, { height: 200, width: 200 }) ||
                    videoPlaceholder
                  }
                  onRemove={() => handleRemove(item)}
                >
                  <Preview.Title bgColor={item.color}>
                    {item.firstName} {item.lastName}
                  </Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                bgImg={
                  getImageUrl(value.imageURL, { height: 200, width: 200 }) ||
                  videoPlaceholder
                }
                onRemove={handleRemove}
              >
                <Preview.Title bgColor={value.color}>
                  {value.firstName} {value.lastName}
                </Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button id={fieldId} htmlType="button" onClick={openModal}>
          {value.id ? 'Change' : 'Select'} {multiple ? 'Speakers' : 'Speaker'}
        </Button>
      </Space>
    </>
  );
};

export const SelectAsset = ({
  id,
  value,
  onChange,
  dataSelector,
  modalTitle,
  categoryKey,
  btnText,
  multiple = false,
  previewImageKey = '',
  allowClear = true
}) => {
  const { colorText } = theme.useToken().token;

  const { open, openModal, closeModal } = useModal();
  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value.find((items) => items.id === item.id);
      if (isPresent) {
        onChange(value.filter((items) => items.id !== item.id));
      } else {
        onChange([...value, dataSelector(item)]);
      }
      return;
    }
    onChange(dataSelector(item));
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value.filter((items) => items.id !== item.id));
    } else {
      onChange({ ...initialAsset });
    }
  };

  return (
    <>
      <SelectableModal
        title={modalTitle}
        open={open}
        onClose={closeModal}
        query={GET_ASSETS}
        variablesSelector={(offset, limit, search) => ({
          filter: { categoryKey, limit, skip: offset, search }
        })}
        dataSelector={(data) => data.assets.assets}
        keys={{
          data: 'assets',
          records: 'assets',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value.map((val) => val.id).includes(item.id)
            : value?.id === item?.id;

          return (
            <Card
              className={`selectable-modal-card cs-card ${
                isSelected ? 'active' : ''
              }`}
              cover={
                <>
                  {isSelected && (
                    <span className="checked-icon">
                      <CheckOutlined />
                    </span>
                  )}
                  <Image
                    blurHash={item?.blurhash}
                    className="label-poster"
                    src={getAssetImage(item, categoryKey)}
                    alt=""
                  />
                </>
              }
              onClick={() => handleCardSelect(item)}
            >
              <Card.Meta
                title={<span className="label-title">{item.title}</span>}
                description={
                  <Typography.Paragraph
                    className="asset-description"
                    ellipsis={{ rows: 2 }}
                  >
                    {moment(item.createdAt).format('ll')}
                  </Typography.Paragraph>
                }
              />
            </Card>
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  {...(allowClear && {
                    onRemove: () => handleRemove(item)
                  })}
                  key={item.id}
                  bgImg={
                    item[previewImageKey] ??
                    DEFAULT_PLACEHOLDER_IMAGE[categoryKey]
                  }
                >
                  <Preview.Title color={colorText}>{item.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                {...(allowClear && {
                  onRemove: handleRemove
                })}
                bgImg={value?.url ?? DEFAULT_PLACEHOLDER_IMAGE[categoryKey]}
              />
            )}
          </>
        )}
        <Button id={id} htmlType="button" onClick={openModal}>
          {value?.id ? 'Change' : 'Select'} {btnText}
        </Button>
      </Space>
    </>
  );
};

export const Editor = ({ value, onChange, modules = EDITOR_MODULES }) => {
  return (
    <ReactQuill
      value={value}
      onChange={(val) => {
        if (val === '<p><br></p>') onChange('');
        else onChange(val);
      }}
      modules={modules}
    />
  );
};

const platformVariableSelctor = ({ skip, limit, search }) => ({
  filter: {
    skip,
    limit,
    search
  }
});

export const SelectPlatForm = ({ value, onChange, setPlatform }) => {
  return (
    <Select
      dropdownMatchSelectWidth={false}
      placement="bottomRight"
      variablesSelector={platformVariableSelctor}
      value={value}
      onChange={onChange}
      placeholder="Select platform"
      className="video-platform-select-filter"
      query={GET_VIDEO_PLATFORMS}
      dataSelector={(data) =>
        data?.videoPlatforms?.videoPlatforms?.map(
          ({ id, provider: { name: providerName }, name }) => ({
            label: `${providerName} - ${name}`,
            value: id
          })
        ) ?? []
      }
      keys={{
        data: 'videoPlatforms',
        records: 'videoPlatforms',
        count: 'count'
      }}
      limit={6}
      queryOptions={{
        onCompleted: ({ videoPlatforms }) => {
          const firstPlatform = videoPlatforms?.videoPlatforms?.[0];
          if (firstPlatform) {
            if (!value)
              setPlatform({
                label: `${firstPlatform?.name} - ${firstPlatform?.provider?.name}`,
                value: firstPlatform?.id
              });
          }
        }
      }}
    />
  );
};

const platformVideosVariableSelector = (offset, limit, search, filters) => ({
  filter: {
    skip: offset,
    limit,
    search
  },
  where: {
    id: filters.platform
  }
});

export const SelectProviderVideo = ({
  value,
  onChange,
  multiple = false,
  id: fieldId
}) => {
  const { open, openModal, closeModal } = useModal();
  const [platform, setPlatform] = useState(null);

  const handleCardSelect = (item, platformProvider) => {
    const { id, title, description, imageThumbnailUrl } = item;
    if (multiple) {
      const isPresent = value.find((items) => items.id === id);
      if (isPresent) {
        onChange(value.filter((items) => items.id !== id));
      } else {
        onChange([
          ...value,
          {
            id,
            title,
            description,
            imageURL: imageThumbnailUrl ?? '',
            platformId: platformProvider?.value
          }
        ]);
      }
      return;
    }
    onChange({
      id,
      title,
      description,
      imageURL: imageThumbnailUrl ?? '',
      platformId: platformProvider?.value
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value.filter((items) => items.id !== item.id));
    } else {
      onChange({
        ...initialAuthor
      });
    }
  };

  const handlePlatformChange = (_, newValue) => {
    setPlatform(newValue);
  };

  const filters = useMemo(
    () => ({ platform: platform ? platform?.value : '' }),
    [platform]
  );

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Videos' : 'Select Video'}
        open={open}
        onClose={closeModal}
        query={GET_VIDEO_BY_PLATFORM}
        variablesSelector={platformVideosVariableSelector}
        dataSelector={(data) => data.getVideosByPlatform.platformVideos}
        queryOptions={{
          skip: !platform
        }}
        keys={{
          data: 'getVideosByPlatform',
          records: 'platformVideos',
          count: 'count'
        }}
        filters={filters}
        renderFilters={() => (
          <SelectPlatForm
            onChange={handlePlatformChange}
            value={platform}
            setPlatform={setPlatform}
          />
        )}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value.map((val) => val.id).includes(item.id)
            : value?.id === item?.id;
          return (
            <Card
              className="selectable-modal-card cs-card"
              cover={
                <>
                  {isSelected && (
                    <span className="checked-icon">
                      <CheckOutlined />
                    </span>
                  )}
                  <Image
                    className="label-poster"
                    src={
                      item?.videoThumbnailUrl ??
                      item?.imageThumbnailUrl ??
                      videoPlaceholder
                    }
                    alt=""
                  />
                </>
              }
              onClick={() => handleCardSelect(item, platform)}
            >
              <Card.Meta
                title={<span className="label-title">{`${item.title}`}</span>}
              />
            </Card>
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value.length > 0 &&
              value.map((item) => (
                <Preview
                  key={item.id}
                  bgImg={item?.imageURL ?? videoPlaceholder}
                  onRemove={() => handleRemove(item)}
                >
                  <Preview.Title bgColor={item.color}>
                    {item.title}
                  </Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                bgImg={value?.imageURL ?? videoPlaceholder}
                onRemove={handleRemove}
              >
                <Preview.Title>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button id={fieldId} htmlType="button" onClick={openModal}>
          {value?.id ? 'Change' : 'Select'} {multiple ? 'Videos' : 'Video'}
        </Button>
      </Space>
    </>
  );
};
