import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PAGES = gql`
  query pagesAdmin($filter: PagesAdminFilter, $sort: PagesSort) {
    pagesAdmin(filter: $filter, sort: $sort) {
      count
      pages {
        id
        slug
        title
        description
        status
        type
        isDefault
        allowDelete
        createdBy {
          id
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PAGE = gql`
  query pageAdmin($where: PageUniqueInput!) {
    pageAdmin(where: $where) {
      id
      slug
      title
      description
      status
      type
      isDefault
      metaHeader
      metaFooter
      createdBy {
        id
        email
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_PAGE_MODULE_TYPES = gql`
  query pageModuleTypes {
    pageModuleTypes {
      key
      name
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query permissions {
    permissions {
      count
      permissions {
        key
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TOPIC = gql`
  query topicsAdmin($filter: TopicsFilter) {
    topicsAdmin(filter: $filter) {
      count
      topics {
        id
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TAGS = gql`
  query tagsAdmin($filter: TagsFilter) {
    tagsAdmin(filter: $filter) {
      count
      tags {
        key
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_AUTHORS = gql`
  query authorsAdmin($filter: AuthorsFilter) {
    authorsAdmin(filter: $filter) {
      count
      authors {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_COLLECTIONS = gql`
  query collectionsAdmin($filter: CollectionsFilter) {
    collectionsAdmin(filter: $filter) {
      count
      collections {
        id
        title
      }
    }
  }
`;
const video = `
    video {
      id
      title
      videoThumbnail {
        url
      }
    }
`;
const videos = `
  videos {
    order
    ${video}
  }
`;

const getVideoConfig = (configKey, key, isMultiVideo = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiVideo ? videos : video}
    autoGenerate
    autoGenerateByType
    videoAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateByData {
      ... on Author {
        authorId: id
        authorFirstName: firstName,
        authorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: key
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
    }
  }
`;

const VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT = gql`
  fragment videoContinueWatchingCarouselData on VideoContinueWatchingCarouselData {
    settings {
      title
      description
      viewAll
      videoTimestamp
      videoDate
      videoAuthor
      videoTopic
      videoTag
      videoOrder
    }
  }
`;
const VIDEO_WATCH_LATER_CAROUSEL_FRAGMENT = gql`
  fragment videoWatchLaterCarouselData on VideoWatchLaterCarouselData {
    settings {
      title
      description
      viewAll
      videoTimestamp
      videoDate
      videoAuthor
      videoTopic
      videoTag
      videoOrder
    }
  }
`;

const VIDEO_CAROUSEL_CONFIG_FRAGMENT = gql(
  getVideoConfig('carouselConfig', 'VideoCarouselModuleConfigData')
);
const VIDEO_CAROUSEL_FRAGMENT = gql`
  ${VIDEO_CAROUSEL_CONFIG_FRAGMENT}
  fragment videoCarouselData on VideoCarouselData {
    settings {
      description
      viewAll
      videoTimestamp
      videoDate
      videoAuthor
      videoTopic
      videoTag
      videoOrder
      videoProgressBar
    }
    config {
      ...carouselConfig
    }
  }
`;

const VIDEO_LIST_CONFIG_FRAGMENT = gql(
  getVideoConfig('listConfig', 'VideoListModuleConfigData')
);
const VIDEO_LIST_FRAGMENT = gql`
  ${VIDEO_LIST_CONFIG_FRAGMENT}
  fragment videoListFragment on VideoListData {
    settings {
      description
      separator
      searchFilter
      tagsFilter
      videoTimestamp
      videoDate
      videoAuthor
      videoTopic
      videoTag
      videoOrder
      videoProgressBar
      videoDescription
      viewAll
    }
    config {
      ...listConfig
    }
  }
`;

const VIDEO_GRID_CONFIG_FRAGMENT = gql(
  getVideoConfig('gridConfig', 'VideoGridModuleConfigData')
);
const VIDEO_GRID_FRAGMENT = gql`
  ${VIDEO_GRID_CONFIG_FRAGMENT}
  fragment videoGridFragment on VideoGridData {
    settings {
      title
      description
      searchFilter
      tagsFilter
      videoTimestamp
      videoDate
      videoAuthor
      videoTopic
      videoTag
      videoOrder
      videoProgressBar
      viewAll
    }
    config {
      ...gridConfig
    }
  }
`;
const FEATURED_VIDEO_CONFIG_FRAGMENT = gql(
  getVideoConfig('featureVideoConfig', 'FeaturedVideoModuleConfigData', false)
);
const FEATURED_VIDEO_FRAGMENT = gql`
  ${FEATURED_VIDEO_CONFIG_FRAGMENT}
  fragment featuredVideoFragment on FeaturedVideoData {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoAuthor
      videoTopic
      videoTag
      videoCTA
    }
    config {
      ...featureVideoConfig
    }
  }
`;

const FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT = gql(
  getVideoConfig(
    'featureVideoSliderConfig',
    'FeaturedVideoSliderModuleConfigData'
  )
);
const FEATURED_VIDEO_SLIDER_FRAGMENT = gql`
  ${FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT}
  fragment featuredVideoSlicerFragment on FeaturedVideoSliderData {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoAuthor
      videoTopic
      videoTag
      carouselIndicator
      videoCTA
    }
    config {
      ...featureVideoSliderConfig
    }
  }
`;

const VIDEO_PLAYER_FRAGMENT = gql`
  fragment videoPlayerFragment on VideoPlayerData {
    settings {
      videoDescription
      videoTimestamp
      videoDate
      videoAuthor
      videoTopic
      videoTag
      videoOrder
    }
    config {
      video {
        id
        title
        videoThumbnail {
          url
        }
      }
    }
  }
`;

const collection = `
  collection {
    id
    title
    thumbnail {
      url
    }
  }
`;
const collections = `
  collections {
    order
    ${collection}
  }
`;
const getCollectionConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    aspectRatio
    collectionAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? collections : collection}
  }
`;

const COLLECTION_CAROUSEL_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionCarouselConfigFragment',
    'CollectionCarouselModuleConfigData'
  )
);

const COLLECTION_CAROUSEL_FRAGMENT = gql`
  ${COLLECTION_CAROUSEL_CONFIG_FRAGMENT}
  fragment collectionCarouselFragment on CollectionCarouselData {
    settings {
      description
      viewAll
      collectionNumberOfVideos
    }
    config {
      ...collectionCarouselConfigFragment
    }
  }
`;

const COLLECTION_GRID_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionGridConfigFragment',
    'CollectionGridModuleConfigData'
  )
);

const COLLECTION_GRID_FRAGMENT = gql`
  ${COLLECTION_GRID_CONFIG_FRAGMENT}
  fragment collectionGridFragment on CollectionGridData {
    settings {
      description
      allText
      searchBar
      collectionNumberOfVideos
      viewAll
    }
    config {
      ...collectionGridConfigFragment
    }
  }
`;

const COLLECTION_LIST_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionListConfigFragment',
    'CollectionListModuleConfigData'
  )
);
const COLLECTION_LIST_FRAGMENT = gql`
  ${COLLECTION_LIST_CONFIG_FRAGMENT}
  fragment collectionListFragment on CollectionListData {
    settings {
      description
      separator
      collectionDescription
      collectionNumberOfVideos
      viewAll
    }
    config {
      ...collectionListConfigFragment
    }
  }
`;

const FEATURED_COLLECTION_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionConfigFragment',
    'FeaturedCollectionModuleConfigData',
    false
  )
);
const FEATURED_COLLECTION_FRAGMENT = gql`
  ${FEATURED_COLLECTION_CONFIG_FRAGMENT}
  fragment featuredCollectionFragment on FeaturedCollectionData {
    settings {
      collectionDescription
    }
    config {
      ...featuredCollectionConfigFragment
    }
  }
`;

const FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionSliderConfigFragment',
    'FeaturedCollectionSliderModuleConfigData'
  )
);
const FEATURED_COLLECTION_SLIDER_FRAGMENT = gql`
  ${FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT}
  fragment featuredCollectionSliderFragment on FeaturedCollectionSliderData {
    settings {
      collectionTitle
      collectionDescription
      collectionImage
    }
    config {
      ...featuredCollectionSliderConfigFragment
    }
  }
`;

const author = `
  author {
    id
    firstName
    lastName
    thumbnail {
      url
    }
    image {
      url
    }
    primaryColor
  }
`;
const authors = `
  authors {
    order
    ${author}
  }
`;
const getAuthorConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    authorAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? authors : author}
  }
`;

const AUTHOR_CAROUSEL_CONFIG_FRAGMENT = gql(
  getAuthorConfig(
    'authorCarouselConfigFragment',
    'AuthorCarouselModuleConfigData'
  )
);
const AUTHOR_CAROUSEL_FRAGMENT = gql`
  ${AUTHOR_CAROUSEL_CONFIG_FRAGMENT}
  fragment authorCarouselFragment on AuthorCarouselData {
    settings {
      description
      viewAll
      authorNumberOfVideos
    }
    config {
      ...authorCarouselConfigFragment
    }
  }
`;

const AUTHOR_LIST_CONFIG_FRAGMENT = gql(
  getAuthorConfig('authorListConfigFragment', 'AuthorListModuleConfigData')
);

const AUTHOR_LIST_FRAGMENT = gql`
  ${AUTHOR_LIST_CONFIG_FRAGMENT}
  fragment authorListFragment on AuthorListData {
    settings {
      description
      separator
      authorDescription
      authorNumberOfVideos
      viewAll
    }
    config {
      ...authorListConfigFragment
    }
  }
`;

const AUTHOR_GRID_CONFIG_FRAGMENT = gql(
  getAuthorConfig('authorGridConfigFragment', 'AuthorGridModuleConfigData')
);
const AUTHOR_GRID_FRAGMENT = gql`
  ${AUTHOR_GRID_CONFIG_FRAGMENT}
  fragment authorGridFragment on AuthorGridData {
    settings {
      description
      authorNumberOfVideos
      viewAll
    }
    config {
      ...authorGridConfigFragment
    }
  }
`;

const FEATURED_AUTHOR_CONFIG_FRAGMENT = gql(
  getAuthorConfig(
    'featuredAuthorGridConfigFragment',
    'FeaturedAuthorModuleConfigData',
    false
  )
);
const FEATURED_AUTHOR_FRAGMENT = gql`
  ${FEATURED_AUTHOR_CONFIG_FRAGMENT}
  fragment featuredAuthorGridFragment on FeaturedAuthorData {
    settings {
      authorName
      authorNumberOfVideos
      authorImage
    }
    config {
      ...featuredAuthorGridConfigFragment
    }
  }
`;

const tag = `
  tag {
    key
    name
  }
`;
const tags = `
  tags {
    order
    ${tag}
  }
`;
const getTagConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    tagAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? tags : tag}
  }
`;

const TAG_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCarouselConfigFragment', 'TagCarouselModuleConfigData')
);
const TAG_CAROUSEL_FRAGMENT = gql`
  ${TAG_CAROUSEL_CONFIG_FRAGMENT}
  fragment tagCarouselFragment on TagCarouselData {
    settings {
      viewAll
    }
    config {
      ...tagCarouselConfigFragment
    }
  }
`;

const TAG_CLOUD_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCloudConfigFragment', 'TagCloudModuleConfigData')
);
const TAG_CLOUD_FRAGMENT = gql`
  ${TAG_CLOUD_CONFIG_FRAGMENT}
  fragment tagCloudFragment on TagCloudData {
    settings {
      title
      description
      viewAll
    }
    config {
      ...tagCloudConfigFragment
    }
  }
`;

const FEATURED_TAG_CONFIG_FRAGMENT = gql(
  getTagConfig(
    'featuredTagConfigFragment',
    'FeaturedTagModuleConfigData',
    false
  )
);
const FEATURED_TAG_FRAGMENT = gql`
  ${FEATURED_TAG_CONFIG_FRAGMENT}
  fragment featuredTagFragment on FeaturedTagData {
    settings {
      tagTitle
    }
    config {
      ...featuredTagConfigFragment
    }
  }
`;

const topic = `
  topic {
    id
    name
  }
`;
const topics = `
  topics {
    order
    ${topic}
  }
`;
const getTopicsConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    topicAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? topics : topic}
  }
`;

const TOPIC_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'topicCarouselConfigFragment',
    'TopicCarouselModuleConfigData'
  )
);
const TOPIC_CAROUSEL_FRAGMENT = gql`
  ${TOPIC_CAROUSEL_CONFIG_FRAGMENT}
  fragment topicCarouselFragment on TopicCarouselData {
    settings {
      description
      viewAll
      topicTitle
    }
    config {
      ...topicCarouselConfigFragment
    }
  }
`;

const TOPIC_LIST_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicListConfigFragment', 'TopicListModuleConfigData')
);
const TOPIC_LIST_FRAGMENT = gql`
  ${TOPIC_LIST_CONFIG_FRAGMENT}
  fragment topicListFragment on TopicListData {
    settings {
      topicTitle
      viewAll
    }
    config {
      ...topicListConfigFragment
    }
  }
`;

const TOPIC_GRID_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicGridConfigFragment', 'TopicGridModuleConfigData')
);
const TOPIC_GRID_FRAGMENT = gql`
  ${TOPIC_GRID_CONFIG_FRAGMENT}
  fragment topicGridFragment on TopicGridData {
    settings {
      description
      topicTitle
      viewAll
    }
    config {
      ...topicGridConfigFragment
    }
  }
`;

const FEATURED_TOPIC_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'featuredTopicConfigFragment',
    'FeaturedTopicModuleConfigData',
    false
  )
);
const FEATURED_TOPIC_FRAGMENT = gql`
  ${FEATURED_TOPIC_CONFIG_FRAGMENT}
  fragment featuredTopicFragment on FeaturedTopicData {
    settings {
      topicTitle
    }
    config {
      ...featuredTopicConfigFragment
    }
  }
`;

const CTA_BLOCK_FRAGMENT = gql`
  fragment ctaBlockFragment on CTABlockData {
    settings {
      image
      description
      secondaryAction
    }
    config {
      asset {
        id
        url
      }
      theme
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;
const CTA_BLOCK_EVENT_FRAGMENT = gql`
  fragment ctaBlockEventFragment on CTAEventBlockData {
    settings {
      image
      description
      secondaryAction
      eventDetails
    }
    config {
      asset {
        id
        url
      }
      theme
      eventTime
      eventLocation
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const CTA_BANNER_WITH_TEXT_FRAGMENT = gql`
  fragment ctaBannerWithTextFragment on CTABannerWithTextData {
    settings {
      title
      image
      description
    }
    config {
      asset {
        id
        url
      }
      theme

      primaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const HERO_FRAGMENT = gql`
  fragment heroFragment on HeroData {
    settings {
      description
      primaryAction
      secondaryAction
      textFields
    }
    config {
      asset {
        id
        url
        categoryKey
        serviceImageThumbnail
        serviceVideoThumbnail
      }
      eventTime
      eventLocation
      alignment
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const INFORMATION_FRAGMENT = gql`
  fragment informationFragment on InformationData {
    settings {
      description
      primaryAction
      secondaryAction
    }
    config {
      asset {
        id
        url
      }
      theme
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const CTA_BANNER_FRAGMENT = gql`
  fragment ctaBannerFragment on CTABannerData {
    config {
      asset {
        id
        url
      }
    }
  }
`;

const DONATION_FRAGMENT = gql`
  fragment donationFragment on DonationData {
    settings {
      description
      secondaryTitleAndDescription
    }
    config {
      key
      video {
        id
        title
        videoThumbnail {
          url
        }
      }
      theme
      secondaryTitle
      secondaryDescription
    }
  }
`;

const COPY_HEAVY_FRAGMENT = gql`
  fragment copyHeavyFragment on CopyHeavyData {
    settings {
      title
      date
      author
      textFields
      primaryAction
      secondaryAction
    }
    config {
      date
      text
      alignment
      author {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const COPY_HEAVY_HERO_FRAGMENT = gql`
  fragment copyHeavyHeroFragment on CopyHeavyHeroData {
    settings {
      title
      date
      author
      textFields
      primaryAction
      secondaryAction
    }
    config {
      asset {
        id
        url
      }
      date
      text
      alignment
      author {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const QUOTE_FRAGMENT = gql`
  fragment quoteFragment on QuoteData {
    settings {
      indicators
      authorName
      authorSubTitle
    }
    config {
      quotes {
        text
        author {
          name
          subTitle
          asset {
            id
            url
          }
        }
      }
    }
  }
`;

const LEAD_GEN_FRAGMENT = gql`
  fragment leadGenFragment on LeadGenData {
    settings {
      title
      description
    }
    config {
      key
      theme
      completionTitle
      completionDescription
    }
  }
`;
const CONTACT_US_FRAGMENT = gql`
  fragment contactUsFragment on ContactUsData {
    settings {
      title
      description
    }
    config {
      key
    }
  }
`;

const FAQS_FRAGMENT = gql`
  fragment faqsFragment on FAQData {
    settings {
      title
      description
    }
    config {
      questions {
        question
        answer
      }
    }
  }
`;
const SPACER_FRAGMENT = gql`
  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }
`;

export const PAGE_MODULE_DETAILS_FRAGMENT = gql`
  ${VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT}
  ${VIDEO_WATCH_LATER_CAROUSEL_FRAGMENT}
  ${VIDEO_CAROUSEL_FRAGMENT}
  ${VIDEO_LIST_FRAGMENT}
  ${VIDEO_GRID_FRAGMENT}
  ${FEATURED_VIDEO_FRAGMENT}
  ${FEATURED_VIDEO_SLIDER_FRAGMENT}
  ${VIDEO_PLAYER_FRAGMENT}
  ${COLLECTION_CAROUSEL_FRAGMENT}
  ${COLLECTION_GRID_FRAGMENT}
  ${COLLECTION_LIST_FRAGMENT}
  ${FEATURED_COLLECTION_FRAGMENT}
  ${FEATURED_COLLECTION_SLIDER_FRAGMENT}
  ${AUTHOR_CAROUSEL_FRAGMENT}
  ${AUTHOR_LIST_FRAGMENT}
  ${AUTHOR_GRID_FRAGMENT}
  ${FEATURED_AUTHOR_FRAGMENT}
  ${TAG_CAROUSEL_FRAGMENT}
  ${TAG_CLOUD_FRAGMENT}
  ${FEATURED_TAG_FRAGMENT}
  ${TOPIC_CAROUSEL_FRAGMENT}
  ${TOPIC_GRID_FRAGMENT}
  ${TOPIC_LIST_FRAGMENT}
  ${FEATURED_TOPIC_FRAGMENT}
  ${CTA_BLOCK_FRAGMENT}
  ${CTA_BLOCK_EVENT_FRAGMENT}
  ${CTA_BANNER_WITH_TEXT_FRAGMENT}
  ${HERO_FRAGMENT}
  ${CTA_BANNER_FRAGMENT}
  ${DONATION_FRAGMENT}
  ${COPY_HEAVY_FRAGMENT}
  ${COPY_HEAVY_HERO_FRAGMENT}
  ${QUOTE_FRAGMENT}
  ${INFORMATION_FRAGMENT}
  ${LEAD_GEN_FRAGMENT}
  ${CONTACT_US_FRAGMENT}
  ${FAQS_FRAGMENT}
  ${SPACER_FRAGMENT}
  fragment pageModuleDetails on PageModule {
    id
    title
    description
    status
    permissions
    type
    isDefaultModule
    moduleData {
      ... on VideoContinueWatchingCarouselData {
        ...videoContinueWatchingCarouselData
      }
      ... on VideoWatchLaterCarouselData {
        ...videoWatchLaterCarouselData
      }
      ... on VideoCarouselData {
        ...videoCarouselData
      }
      ... on VideoListData {
        ...videoListFragment
      }
      ... on VideoGridData {
        ...videoGridFragment
      }
      ... on FeaturedVideoData {
        ...featuredVideoFragment
      }
      ... on FeaturedVideoSliderData {
        ...featuredVideoSlicerFragment
      }
      ... on VideoPlayerData {
        ...videoPlayerFragment
      }
      ... on CollectionCarouselData {
        ...collectionCarouselFragment
      }
      ... on CollectionListData {
        ...collectionListFragment
      }
      ... on CollectionGridData {
        ...collectionGridFragment
      }
      ... on FeaturedCollectionData {
        ...featuredCollectionFragment
      }
      ... on FeaturedCollectionSliderData {
        ...featuredCollectionSliderFragment
      }
      ... on AuthorCarouselData {
        ...authorCarouselFragment
      }
      ... on AuthorListData {
        ...authorListFragment
      }
      ... on AuthorGridData {
        ...authorGridFragment
      }
      ... on FeaturedAuthorData {
        ...featuredAuthorGridFragment
      }
      ... on TagCarouselData {
        ...tagCarouselFragment
      }
      ... on TagCloudData {
        ...tagCloudFragment
      }
      ... on FeaturedTagData {
        ...featuredTagFragment
      }
      ... on TopicCarouselData {
        ...topicCarouselFragment
      }
      ... on TopicListData {
        ...topicListFragment
      }
      ... on TopicGridData {
        ...topicGridFragment
      }
      ... on FeaturedTopicData {
        ...featuredTopicFragment
      }
      ... on CTABlockData {
        ...ctaBlockFragment
      }
      ... on CTAEventBlockData {
        ...ctaBlockEventFragment
      }
      ... on CTABannerWithTextData {
        ...ctaBannerWithTextFragment
      }
      ... on HeroData {
        ...heroFragment
      }
      ... on CTABannerData {
        ...ctaBannerFragment
      }
      ... on DonationData {
        ...donationFragment
      }
      ... on CopyHeavyData {
        ...copyHeavyFragment
      }
      ... on CopyHeavyHeroData {
        ...copyHeavyHeroFragment
      }
      ... on QuoteData {
        ...quoteFragment
      }
      ... on InformationData {
        ...informationFragment
      }
      ... on LeadGenData {
        ...leadGenFragment
      }
      ... on ContactUsData {
        ...contactUsFragment
      }
      ... on FAQData {
        ...faqsFragment
      }
      ... on SpacerData {
        ...spaceFragment
      }
    }
  }
`;

export const GET_PAGE_DETAILS = gql`
  ${PAGE_MODULE_DETAILS_FRAGMENT}
  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }) {
      slug
      title
      description
      metaHeader
      metaFooter
      permissions
      allowDelete
      isDefault
      type
      modules {
        ...pageModuleDetails
      }
    }
  }
`;

export const GET_PAGE_DETAILS_FOR_PREVIEW = gql`
  fragment AssetFragment on Asset {
    categoryKey
    url
    blurhash
    serviceVideoThumbnail
    serviceImageThumbnail
  }

  fragment AuthorFragment on Author {
    id
    firstName
    lastName
    subTitle
    description
    image {
      url
      blurhash
    }
    thumbnail {
      url
      blurhash
    }
    primaryColor
    videosCount
  }

  fragment TopicFragment on Topic {
    id
    key
    name
    primaryColor
  }

  fragment TagFragment on Tag {
    key
    name
  }

  fragment VideoFragment on Video {
    id
    title
    description
    topics {
      id
      name
      primaryColor
    }
    authors {
      id
      firstName
      lastName
      image {
        url
        blurhash
      }
    }
    tags {
      key
      name
    }
    createdAt
    video {
      id
      url
      information {
        durationInSeconds
      }
    }
    videoThumbnail {
      url
      blurhash
    }
  }

  fragment CollectionFragment on Collection {
    id
    title
    videosCount
    thumbnail {
      url
      blurhash
    }
  }

  fragment VideoContinueWatchingCarouselFragment on VideoContinueWatchingCarouselData {
    settings {
      viewAll
    }
    config {
      videos {
        video {
          ...VideoFragment
        }
        order
      }
    }
  }

  fragment VideoWatchLaterCarouselFragment on VideoWatchLaterCarouselData {
    settings {
      viewAll
    }
    config {
      videos {
        video {
          ...VideoFragment
        }
        order
      }
    }
  }

  fragment VideoCarouselFragment on VideoCarouselData {
    settings {
      viewAll
      videoProgressBar
    }
    config {
      videos {
        video {
          ...VideoFragment
        }
        order
      }
    }
  }

  fragment VideoListFragment on VideoListData {
    settings {
      videoProgressBar
      separator
      viewAll
    }
    config {
      videos {
        video {
          ...VideoFragment
        }
        order
      }
    }
  }

  fragment VideoGridFragment on VideoGridData {
    settings {
      videoProgressBar
      viewAll
    }
    config {
      videos {
        video {
          ...VideoFragment
        }
        order
      }
    }
  }

  fragment FeaturedVideoSliderFragment on FeaturedVideoSliderData {
    settings {
      carouselIndicator
      videoCTA
    }
    config {
      videos {
        video {
          ...VideoFragment
        }
        order
      }
    }
  }

  fragment FeaturedVideoFragment on FeaturedVideoData {
    settings {
      videoCTA
    }
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment VideoPlayerFragment on VideoPlayerData {
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment TopicCarouselFragment on TopicCarouselData {
    settings {
      viewAll
    }
    config {
      topics {
        topic {
          ...TopicFragment
        }
        order
      }
    }
  }

  fragment TopicListFragment on TopicListData {
    settings {
      viewAll
    }
    config {
      topics {
        topic {
          ...TopicFragment
        }
        order
      }
    }
  }

  fragment TopicGridFragment on TopicGridData {
    settings {
      viewAll
    }
    config {
      topics {
        topic {
          ...TopicFragment
        }
        order
      }
    }
  }

  fragment FeaturedTopicFragment on FeaturedTopicData {
    settings {
      topicTitle
    }
    featuredTopicConfig: config {
      autoGenerateSortBy
      autoGenerate
      topic {
        ...TopicFragment
      }
    }
  }

  fragment CollectionCarouselFragment on CollectionCarouselData {
    settings {
      viewAll
      collectionNumberOfVideos
    }
    config {
      aspectRatio
      collections {
        collection {
          ...CollectionFragment
        }
        order
      }
    }
  }

  fragment CollectionGridFragment on CollectionGridData {
    settings {
      collectionNumberOfVideos
      viewAll
    }
    config {
      aspectRatio
      collections {
        collection {
          ...CollectionFragment
        }
        order
      }
    }
  }

  fragment CollectionListFragment on CollectionListData {
    settings {
      separator
      collectionNumberOfVideos
      viewAll
    }
    config {
      aspectRatio
      collections {
        collection {
          ...CollectionFragment
        }
        order
      }
    }
  }

  fragment FeaturedCollectionSliderFragment on FeaturedCollectionSliderData {
    config {
      collections {
        collection {
          ...CollectionFragment
        }
        order
      }
    }
  }

  fragment FeaturedCollectionFragment on FeaturedCollectionData {
    config {
      collection {
        ...CollectionFragment
      }
    }
  }

  fragment AuthorCarouselFragment on AuthorCarouselData {
    settings {
      viewAll
    }
    config {
      authors {
        author {
          ...AuthorFragment
        }
        order
      }
    }
  }

  fragment AuthorGridFragment on AuthorGridData {
    settings {
      viewAll
    }
    config {
      authors {
        author {
          ...AuthorFragment
        }
        order
      }
    }
  }

  fragment AuthorListFragment on AuthorListData {
    settings {
      separator
      viewAll
    }
    config {
      authors {
        author {
          ...AuthorFragment
        }
        order
      }
    }
  }

  fragment FeaturedAuthorFragment on FeaturedAuthorData {
    config {
      author {
        ...AuthorFragment
      }
    }
  }

  fragment TagCarouselFragment on TagCarouselData {
    settings {
      viewAll
    }
    config {
      tags {
        tag {
          ...TagFragment
        }
      }
    }
  }

  fragment TagCloudFragment on TagCloudData {
    settings {
      viewAll
    }
    config {
      tags {
        tag {
          ...TagFragment
        }
      }
    }
  }

  fragment FeaturedTagFragment on FeaturedTagData {
    settings {
      tagTitle
    }
    config {
      autoGenerateSortBy
      autoGenerate
      tag {
        ...TagFragment
      }
    }
  }

  fragment CTABlockFragment on CTABlockData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
      secondaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
      theme
    }
  }
  fragment CTAEventBlockFragment on CTAEventBlockData {
    config {
      eventTime
      eventLocation
      asset {
        ...AssetFragment
      }
      primaryAction {
        title
        url
        type
        internalPageType
      }
      secondaryAction {
        title
        url
        type
        internalPageType
      }
      theme
    }
  }
  fragment CTABannerWithTextFragment on CTABannerWithTextData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        title
        url
        type
        internalPageType
      }
      theme
    }
  }
  fragment HeroFragment on HeroData {
    config {
      asset {
        ...AssetFragment
      }
      eventTime
      eventLocation
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      alignment
    }
  }

  fragment CTABannerFragment on CTABannerData {
    config {
      asset {
        ...AssetFragment
      }
    }
  }
  fragment DonationFragment on DonationData {
    config {
      video {
        ...VideoFragment
      }
      theme
      secondaryTitle
      secondaryDescription
    }
  }
  fragment CopyHeavyFragment on CopyHeavyData {
    config {
      date
      text
      alignment
      author {
        id
        firstName
        lastName
        subTitle
        image {
          url
          blurhash
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }

  fragment CopyHeavyHeroFragment on CopyHeavyHeroData {
    config {
      asset {
        ...AssetFragment
      }
      date
      text
      alignment
      author {
        id
        firstName
        lastName
        subTitle
        image {
          url
          blurhash
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }

  fragment QuoteFragment on QuoteData {
    settings {
      indicators
    }
    config {
      quotes {
        text
        author {
          name
          subTitle
          asset {
            ...AssetFragment
          }
        }
      }
    }
  }

  fragment informationFragment on InformationData {
    config {
      asset {
        url
        blurhash
      }
      theme
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
    }
  }

  fragment leadGenFragment on LeadGenData {
    config {
      key
      theme
    }
  }
  fragment contactUsFragment on ContactUsData {
    config {
      key
    }
  }

  fragment faqsFragment on FAQData {
    config {
      questions {
        question
        answer
      }
    }
  }

  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }

  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }, data: { applySettings: true }) {
      slug
      type
      modules {
        id
        type
        order
        title
        description
        moduleData {
          ... on VideoContinueWatchingCarouselData {
            ...VideoContinueWatchingCarouselFragment
          }
          ... on VideoWatchLaterCarouselData {
            ...VideoWatchLaterCarouselFragment
          }
          ... on VideoCarouselData {
            ...VideoCarouselFragment
          }
          ... on VideoListData {
            ...VideoListFragment
          }
          ... on VideoGridData {
            ...VideoGridFragment
          }
          ... on FeaturedVideoData {
            ...FeaturedVideoFragment
          }
          ... on VideoPlayerData {
            ...VideoPlayerFragment
          }
          ... on FeaturedVideoSliderData {
            ...FeaturedVideoSliderFragment
          }
          ... on CollectionGridData {
            ...CollectionGridFragment
          }
          ... on CollectionCarouselData {
            ...CollectionCarouselFragment
          }
          ... on CollectionListData {
            ...CollectionListFragment
          }
          ... on FeaturedCollectionData {
            ...FeaturedCollectionFragment
          }
          ... on FeaturedCollectionSliderData {
            ...FeaturedCollectionSliderFragment
          }
          ... on AuthorListData {
            ...AuthorListFragment
          }
          ... on AuthorGridData {
            ...AuthorGridFragment
          }
          ... on AuthorCarouselData {
            ...AuthorCarouselFragment
          }
          ... on FeaturedAuthorData {
            ...FeaturedAuthorFragment
          }
          ... on TopicListData {
            ...TopicListFragment
          }
          ... on TopicGridData {
            ...TopicGridFragment
          }
          ... on TopicCarouselData {
            ...TopicCarouselFragment
          }
          ... on FeaturedTopicData {
            ...FeaturedTopicFragment
          }
          ... on TagCloudData {
            ...TagCloudFragment
          }
          ... on TagCarouselData {
            ...TagCarouselFragment
          }
          ... on FeaturedTagData {
            ...FeaturedTagFragment
          }
          ... on CTABlockData {
            ...CTABlockFragment
          }
          ... on CTAEventBlockData {
            ...CTAEventBlockFragment
          }
          ... on CTABannerWithTextData {
            ...CTABannerWithTextFragment
          }
          ... on HeroData {
            ...HeroFragment
          }
          ... on CTABannerData {
            ...CTABannerFragment
          }
          ... on DonationData {
            ...DonationFragment
          }
          ... on CopyHeavyData {
            ...CopyHeavyFragment
          }
          ... on CopyHeavyHeroData {
            ...CopyHeavyHeroFragment
          }
          ... on QuoteData {
            ...QuoteFragment
          }
          ... on InformationData {
            ...informationFragment
          }
          ... on LeadGenData {
            ...leadGenFragment
          }
          ... on ContactUsData {
            ...contactUsFragment
          }
          ... on FAQData {
            ...faqsFragment
          }
          ... on SpacerData {
            ...spaceFragment
          }
        }
      }
    }
  }
`;
